export const localeDate = (
  date,
  locale = 'en-US',
  type = 'all' // date, time, all
) => {
  let options = {}

  if (type === 'all') {
    options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  }

  if (type === 'date') {
    options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    }
  }

  if (type === 'time') {
    options = {
      hour: 'numeric',
      minute: 'numeric'
    }
  }

  const localizedDate = new Date(date).toLocaleString(locale, options)

  return localizedDate
}

export const localeNumber = (
  number,
  decimals = false,
  currency = false,
  locale = 'en-US'
) => {
  const options = {
    ...decimals ? { minimumFractionDigits: 2 } : null,
    ...currency ? { style: 'currency', currency: currency || 'USD' } : null
  }

  const localizedNumber = number.toLocaleString(locale, options)

  return localizedNumber
}
