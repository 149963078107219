<template>
  <a
    v-if="href"
    :href="href"
    :target="target"
    :class="['app-button', ...buttonClass, {'is-disabled': isDisabled, 'is-loading': loading}]"
    :disabled="isDisabled"
  >
    <AppLoading v-if="loading" class="mr-2 h-5 w-5" />

    <slot v-else />
  </a>

  <router-link
    v-else-if="to"
    :to="to"
    :class="['app-button', ...buttonClass, {'is-disabled': isDisabled, 'is-loading': loading}]"
    :disabled="isDisabled"
  >
    <AppLoading v-if="loading" class="mr-2 h-5 w-5" />

    <slot v-else />
  </router-link>

  <button
    v-else
    :class="['app-button', ...buttonClass, {'is-disabled': isDisabled, 'is-loading': loading}]"
    :disabled="isDisabled"
  >
    <AppLoading v-if="loading" class="mr-8 h-20 w-20" />

    <slot v-else />
  </button>
</template>

<script>
import { defineComponent, computed } from 'vue'

import AppLoading from '@/components/stateless/AppLoading.vue'

const EButtonVariants = {
  default: 'app-button--default',
  primary: 'app-button--primary',
  'primary-white': 'app-button--primary-white',
  'default-blue': 'app-button--default-blue',
  danger: 'app-button--danger'
}

const EButtonSizes = {
  sm: 'app-button--sm',
  md: 'app-button--md',
  standart: 'app-button--standart',
  'standart-sm': 'app-button--standart-sm'
}

export default defineComponent({
  name: 'AppButton',

  components: {
    AppLoading
  },

  props: {
    variant: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'standart'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: [Object, null],
      default: null
    },
    href: {
      type: String,
      default: null
    },
    target: {
      type: [String, null],
      default: '_blank'
    }
  },

  setup (props) {
    const buttonClass = [EButtonVariants[props.variant], EButtonSizes[props.size]]

    const isDisabled = computed(() => {
      return !!(props.disabled || props.loading)
    })

    return {
      buttonClass,
      isDisabled
    }
  }
})
</script>

<style lang="scss" scoped>
.app-button {
  @apply inline-flex justify-center items-center text-center font-semibold leading-none rounded-4
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:border-indigo-500
    transition duration-200;

  &.is-disabled {
    @apply cursor-not-allowed opacity-50;
  }

  // Variants
  &--default {
    @apply text-blue-600 hover:text-blue-700 border border-blue-200 hover:border-blue-300 hover:bg-blueGray-50;

    &.is-disabled {
      @apply hover:text-blue-600 hover:border-blue-200 hover:bg-transparent;
    }
  }
  &--default-blue {
    @apply text-white border bg-blue-600 border-blue-500 hover:border-blue-700 hover:bg-blue-700;
  }

  &--danger {
    @apply text-white bg-red-500 hover:bg-red-600;

    &.is-disabled {
      @apply hover:bg-red-600;
    }
  }

  &--primary {
    @apply text-white bg-blue-600 hover:bg-blue-700;

    &.is-disabled {
      @apply hover:bg-blue-600;
    }
  }
  &--primary-white {
    @apply text-blue-600 bg-white hover:bg-blueGray-100;

    &.is-disabled {
      @apply hover:bg-white;
    }
  }

  // Size
  &--sm {
    @apply h-30 px-16 text-12;
  }

  &--md {
    @apply h-30 px-16 text-12;
  }

  &--standart-sm {
    @apply h-30 px-16 text-14;
  }

  &--standart {
    @apply h-40 px-16 text-14;
  }
}
</style>
